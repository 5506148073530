import axios from 'axios';
import AuthService from './AuthService';

const authApi = axios.create({
	baseURL: process.env.REACT_APP_AUTH_URL
});

const eventApi = axios.create({
	baseURL: process.env.REACT_APP_EVENT_URL,
});

const ordersApi = axios.create({
	baseURL: process.env.REACT_APP_ORDERS_URL,
});

const salesApi = axios.create({
	baseURL: process.env.REACT_APP_SALES_URL_V2,
});

const checkTokenApi = axios.create({
	baseURL: process.env.REACT_APP_CHECK_TOKEN_URL,
});

//TODO remove duplications
eventApi.interceptors.request.use(config => {
	const expirationToken: any = AuthService.getTokenExpirationDate();
	config.headers['Content-Type'] = 'application/json';
	config.headers['Accept-Language'] = process.env.REACT_APP_MAIN_LANGUAGE;

	if (expirationToken) {
		if (Date.now() <= parseInt(expirationToken) * 1000) {
			config.headers['Authorization'] = `Bearer ${AuthService.getAccessToken()}`;
			return config;
		}
	}

	config.headers['tenant_id'] = process.env.REACT_APP_TENANT_ID;
	return config;
});

eventApi.interceptors.response.use(undefined, (err) => {
	if (err.response.status === 401) {
		AuthService.clearSession();
	}
	return Promise.reject(err.response);
});

ordersApi.interceptors.request.use(config => {
	const expirationToken: any = AuthService.getTokenExpirationDate();
	config.headers['Content-Type'] = 'application/json';
	config.headers['Accept-Language'] = process.env.REACT_APP_MAIN_LANGUAGE;

	if (expirationToken) {
		if (Date.now() <= parseInt(expirationToken) * 1000) {
			config.headers['Authorization'] = `Bearer ${AuthService.getAccessToken()}`;
			return config;
		}
	}

	config.headers['tenant_id'] = process.env.REACT_APP_TENANT_ID;
	return config;
});

salesApi.interceptors.request.use(config => {
	const expirationToken: any = AuthService.getTokenExpirationDate();
	config.headers['Content-Type'] = 'application/json';
	config.headers['Accept-Language'] = process.env.REACT_APP_MAIN_LANGUAGE;

	if (expirationToken) {
		if (Date.now() <= parseInt(expirationToken) * 1000) {
			config.headers['Authorization'] = `Bearer ${AuthService.getAccessToken()}`;
			return config;
		}
	}

	config.headers['tenant_id'] = process.env.REACT_APP_TENANT_ID;
	return config;
});

salesApi.interceptors.response.use(undefined, (err) => {
	if (err.response.status === 401) {
		AuthService.clearSession();
	}
	return Promise.reject(err.response);
});

authApi.interceptors.request.use(config => {
	const expirationToken: any = AuthService.getTokenExpirationDate();
	config.headers['Content-Type'] = 'application/json';
	config.headers['Accept-Language'] = process.env.REACT_APP_MAIN_LANGUAGE;

	if (expirationToken) {
		if (Date.now() <= parseInt(expirationToken) * 1000) {
			config.headers['Authorization'] = `Bearer ${AuthService.getAccessToken()}`;
			return config;
		}
	}

	config.headers['tenant_id'] = process.env.REACT_APP_TENANT_ID;
	return config;
});

authApi.interceptors.response.use(undefined, (err) => {
	if (err.response.status === 401) {
		AuthService.clearSession();
	}
	return Promise.reject(err.response);
});

checkTokenApi.interceptors.response.use(undefined, (err) => {
	if ([400, 401, 403].includes(err.response.status)) {
		AuthService.clearSession();
		window.location.reload();
	}
	return Promise.reject(err.response);
});

export {
	authApi,
	eventApi,
	ordersApi,
	salesApi,
	checkTokenApi
};
