import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from "react-router-dom";
import { format, add } from 'date-fns';
import { pt } from 'date-fns/locale';
import Cookies from 'universal-cookie';

import { BaseData, Description, EventBanner, EventInformation, TicketsBox, AccreditationMessage } from 'components/event';
import { getEvent, clearEvent } from '../../store/ducks/event/actions';
import { hideFixedFooterContent, hideMobileOptions, showFixedFooterContent, showMobileOptions } from 'store/ducks/nav/actions';
import { dateGlobalPattern } from 'utils/dateUtils';
import { EventState } from 'store/ducks/event/types';
import { Container } from 'styles';
import { Seo } from 'components/standalone';
import PageNotFoundMessage from 'components/pageNotFound/PageNotFoundMessage/PageNotFoundMessage';
import EventService from 'services/EventService';
import PixelManagement from 'components/layout/pixelManagement/PixelManagement';
import AnalyticsManagement from 'components/layout/analyticsManagement/AnalyticsManagement';
import AdditionalMessage from 'components/event/AdditionalMessage/AdditionalMessage';
import { RenderIf } from 'components/layout';

interface StateProps {
	event: EventState,
}

const EventPage = () => {
	const { url } = useParams();
	const select = (state: StateProps) => state.event;
	const dispatch = useDispatch();
	const [affiliateCode, setAffiliateCode] = useState<string|null>(null);
	const [ticketToken, setTicketToken] = useState<string|null>(null);
	const { data, error } = useSelector(select);
	const { search } = useLocation();
	const [meepId, setMeepId] = useState<string>('');

	useEffect(() => {
		dispatch(hideMobileOptions());
		dispatch(showFixedFooterContent());
		getMeepId();
	}, []);

	useEffect(() => {
		dispatch(getEvent(url));
	}, [dispatch]);

	useEffect(() => {
		if (data.id) {
			getTicketToken();
			getAffiliateCode(data.id);
			callCountEventView(data.id);
		}
	}, [data.id]);

	useEffect(() => {
		return () => {
			dispatch(showMobileOptions());
			dispatch(hideFixedFooterContent());
			dispatch(clearEvent())
		}
	}, []);

	const getQueryParams = (param: string) => {
		let query = new URLSearchParams(search);
		return query.get(param) || null;
	}

	const dateHandler = (startDate: string): string => {
		return startDate ? format(new Date(dateGlobalPattern(startDate)), "dd 'de' MMMM 'de' yyyy',' HH:mm", {locale: pt}) : '';
	}

	const getAffiliateCode = (eventId: string) => {
		const affiliateCode = getQueryParams('a');
		const cookies = new Cookies();
		const affiliateCookie = cookies.get(url);

		if (affiliateCode) {
			if (!affiliateCookie) {
				EventService.checkAffiliate(eventId, affiliateCode)
					.then(() => cookies.set(url, affiliateCode, {expires: add(new Date(), { 'weeks': 1 })}));
			}
			setAffiliateCode(affiliateCode);
			return;
		}
		setAffiliateCode(affiliateCookie);
	}

	const getTicketToken = () => {
		const token = getQueryParams('vt');
		setTicketToken(token);
	}

	const getMeepId = () => {
		const mid = getQueryParams('mid') || '';
		setMeepId(mid);
	}

	const callCountEventView = async (eventId: string) => {
		const params = { mid: meepId }
		EventService.countViews(eventId, params)
			.then(({ data }) => setTrackingView(eventId, data));
	}

	const setTrackingView = (eventId: string, hashKey: string) => {
		const payload = {
				latitude: '',
				longitude: '',
		};
		const params = { hashKey };

		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(pos => {
				payload.latitude = pos.coords.latitude.toString();
				payload.longitude = pos.coords.longitude.toString();
				callTrackingView(eventId, params, payload);
			})
		}
	}

	const callTrackingView = (eventId: string, params: any, payload: any) => {
		EventService.countTrackingViews(eventId, params, payload);
	}

	return (
		<>
			{error ? <PageNotFoundMessage /> : (
				<>
					{data.hasMarketing && (<PixelManagement eventId={data.id} />)}
					{data.hasMarketing && (<AnalyticsManagement eventId={data.id} />)}
					<Seo
						title={`${process.env.REACT_APP_NAME} - ${data.name}`}
						description={`${data.name} - ${dateHandler(data.startDate)}`}
						ogDescription={dateHandler(data.startDate)}
						image={data.thumbnail}
						url={data.url}
					/>
					<EventBanner image={data?.featuredBanner ? data?.featuredBanner : data?.banner} />

					<Container padding={isMobile ? 10 : 40}>
						<EventInformation>
							<EventDescriptionWrapper>
								<BaseData
									name={data.name}
									startDate={data.startDate}
									endDate={data.endDate}
									fullAddress={data.fullAddress}
									locationType={data.locationType}
									status={data.status}
								/>

								<Description
									eventId={data.id}
									status={data.status}
									tags={data.tags ? data.tags.split(',') : []}
								/>

								<RenderIf condition={data.showCancellationDescription}>
									<AdditionalMessage eventId={data.id} />
								</RenderIf>

								<RenderIf condition={data.isAccreditation}>
									<AccreditationMessage eventName={data.name} eventUrl={data.url} />
								</RenderIf>
							</EventDescriptionWrapper>

							<RenderIf condition={(data.status === 'PUBLISHED') && (!data.isAccreditation)}>
								<div>
									<TicketsBox
										eventId={data.id}
										meepId={meepId}
										limitedByCpf={data.limitedByCpf}
										affiliateCode={affiliateCode}
										ticketToken={ticketToken}
										salesStartTime={data.salesStartDateInSeconds}
										hasMarketing={data.hasMarketing}
										grouped={data.groupBySector}
										hasDiscount={data.hasDiscount}
									/>
								</div>
							</RenderIf>
						</EventInformation>

					</Container>
				</>
			)}
		</>
	);
};

export default EventPage;

export const EventDescriptionWrapper = styled.div`
	@media(min-width: 991px){
		max-width: calc(100% - 380px);
		width: 100%;
	}
`;
