import styled from 'styled-components';
import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';

type StyleProps = {
	show: boolean;
}

export const CompleteUserRow = styled.div`

	${breakAt(BreakpointsSizes.md)} {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1.5rem;
	}
`;

export const CompleteUserFormContent = styled.div`
	${breakAt(BreakpointsSizes.md, true)} {
		max-height: 70vh;
		overflow-y: auto;
		padding: 0.25rem;
	}
`;

export const CompleteUserFormButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
