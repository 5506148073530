import {
	EventTypes,
	Event,
} from './types';

export const getEvent = (url: string) => ({
	type: EventTypes.GET_EVENT,
	payload: { url }
});

export const loadEvent = (data: Event) => ({
	type: EventTypes.LOAD_EVENT,
	payload: { data },
});

export const loadSuccess = (data: Event) => ({
	type: EventTypes.LOAD_SUCCESS,
	payload: { data },
});

export const loadFailure = () => ({
	type: EventTypes.LOAD_FAILURE,
});

export const clearEvent = () => ({
	type: EventTypes.CLEAR_EVENT,
});
