import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import SwiperCore, { Pagination } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';

import * as S from './styles';
import { HomeState } from 'store/ducks/home/types';
import { getAbout } from 'store/ducks/home/actions';
import { SwiperContainer, SwiperEnd  } from 'styles/Swiper';

SwiperCore.use([Pagination]);

interface StateProps {
	home: HomeState;
}

const About = () => {
	const select = (state: StateProps) => state.home;
	const dispatch = useDispatch();
	const { aboutData, aboutError } = useSelector(select);

	useEffect(() => {
		dispatch(getAbout());
	}, [dispatch]);

	return (
		<>
			{aboutData && !aboutError &&(
				<>
					<S.SectionOneWrapper>
						<S.SectionWrapper>
							<S.AboutTitle align="center" textLight>Faça seu evento valer ainda mais com a plataforma de gestão e venda de ingressos!</S.AboutTitle>
							<S.AboutText align="center">Não importa o tamanho: dos pequenos eventos aos grandes festivais, a Central dos Eventos está pronta para te ajudar na produção, divulgação, gerenciamento, venda e distribuição dos seus ingressos com uma plataforma funcional e segura.</S.AboutText>
							<S.SectionOneCardWrapper>
								<S.SectionOneCard>
									<S.SectionOneCardTitle>MENOR TAXA DO MERCADO</S.SectionOneCardTitle>
									<S.SectionOneCardSubtitle>pagamento antecipado em até 2 dias*!</S.SectionOneCardSubtitle>
									<S.SectionOneCardValue>{aboutData.groupDataOne.fee}%</S.SectionOneCardValue>
								</S.SectionOneCard>
								<S.SectionOneCard>
									<S.SectionOneCardTitle>Eventos gratuitos com</S.SectionOneCardTitle>
									<S.SectionOneCardSubtitle2>TAXA ZERO</S.SectionOneCardSubtitle2>
									<S.SectionOneCardValue>0%</S.SectionOneCardValue>
								</S.SectionOneCard>
							</S.SectionOneCardWrapper>
						</S.SectionWrapper>
					</S.SectionOneWrapper>

					<S.SectionWrapper marginTop={25}>
						<S.AboutTitle align={isMobile ? 'left' : 'center'}>{aboutData.groupDataTwo.title}</S.AboutTitle>
						<S.AboutText>{aboutData.groupDataTwo.descriptionOne}</S.AboutText>
						<S.AboutImage src={aboutData.groupDataTwo.image} />
						<S.AboutText>{aboutData.groupDataTwo.descriptionTwo}</S.AboutText>
					</S.SectionWrapper>

					<S.SectionWrapper marginTop={25}>
						{aboutData.groupDataThree.map((el: any, index: number) => (
							<S.SectionThreeItem key={index} invert={index % 2 === 0}>
								<S.SectionThreeInfo>
									<S.AboutTitle>{el.title}</S.AboutTitle>
									<S.AboutText>{el.description}</S.AboutText>
								</S.SectionThreeInfo>

								<S.AboutImage src={el.image} />
							</S.SectionThreeItem>
						))}
					</S.SectionWrapper>

					<S.SectionWrapper marginTop={25}>
						<S.AboutTitle align="center" style={{marginBottom: 0}}>{aboutData.groupDataFour.titleOne}</S.AboutTitle>
						<S.AboutTitle align="center">{aboutData.groupDataFour.titleTwo}</S.AboutTitle>
						<S.AboutText>{aboutData.groupDataFour.descriptionOne}</S.AboutText>
						<S.AboutText>{aboutData.groupDataFour.descriptionTwo}</S.AboutText>
					</S.SectionWrapper>

					<S.SectionWrapper marginTop={25} noPadding>
						<S.AboutTitle align="center">{aboutData.groupDataFive.title}</S.AboutTitle>

						{isMobile ? (
							<S.SectionFiveMobileWrapper>
								<SwiperContainer>
									<Swiper slidesPerView={1.25} centeredSlides={true} spaceBetween={15} pagination={{clickable: true}}>
										{aboutData.groupDataFive.tags.map((el: any, index: number) => (
											<SwiperSlide key={index} style={{height: 'auto'}}>
												<S.SectionFiveCardWrapper>
													<S.SectionFiveCard>
														<S.SectionFiveCardHeader>
															<S.SectionFiveCardTitle>{el.title}</S.SectionFiveCardTitle>
															<S.SectionFiveCardImage src={el.image} />
														</S.SectionFiveCardHeader>

														{el.description.map((text: any, index: number) => (
															<S.SectionFiveCardContent key={index}>{text}</S.SectionFiveCardContent>
														))}

													</S.SectionFiveCard>
												</S.SectionFiveCardWrapper>
											</SwiperSlide>
										))}
										<SwiperEnd slot="container-end" />
									</Swiper>
								</SwiperContainer>
							</S.SectionFiveMobileWrapper>
						) : (
							<S.SectionFiveWrapper>
								{aboutData.groupDataFive.tags.map((el: any, index: number) => (
									<S.SectionFiveCard key={index}>
										<S.SectionFiveCardHeader>
											<S.SectionFiveCardTitle>{el.title}</S.SectionFiveCardTitle>
											<S.SectionFiveCardImage src={el.image} />
										</S.SectionFiveCardHeader>

										{el.description.map((text: any, index: number) => (
											<S.SectionFiveCardContent key={index}>{text}</S.SectionFiveCardContent>
										))}

									</S.SectionFiveCard>
								))}
							</S.SectionFiveWrapper>
						)}
					</S.SectionWrapper>

					<S.SectionWrapper marginTop={60}>
						<S.AboutText colored>{aboutData.groupDataSix.description}</S.AboutText>
					</S.SectionWrapper>
				</>
			)}
		</>
	);
}

export default About;
