import { eventApi, ordersApi } from './Api';

const EventService = (() => {
	function getEvent(url: string) {
		const params = { url, appType: 'WEB' };
		return ordersApi.get(`/v1/public/event/url`, { params });
	}

	function checkAffiliate(eventId: string, code: string) {
		return ordersApi.get(`/v1/public/affiliate/code?eventId=${eventId}&code=${code}`);
	}

	function getMarketingData(eventId: string) {
		return eventApi.get(`/public/event/${eventId}/marketing`);
	}

	function getAdditionalMessage(eventId: string) {
		return ordersApi.get(`/v1/public/event/${eventId}/cancellation-description`);
	}

	function getEventDescription(eventId: string) {
		return ordersApi.get(`/v1/public/event/${eventId}/description`);
	}

	function countViews(eventId: string, params: any) {
		return ordersApi.get(`/v1/public/event/${eventId}/count-views`, { params });
	}

	function countTrackingViews(eventId: string, params: any, payload: any) {
		return ordersApi.post(`/v1/public/event/${eventId}/tracking-views`, payload, { params });
	}

	return {
		getEvent: getEvent,
		checkAffiliate: checkAffiliate,
		getMarketingData: getMarketingData,
		getAdditionalMessage: getAdditionalMessage,
		getEventDescription: getEventDescription,
		countViews: countViews,
		countTrackingViews: countTrackingViews
	}
})();

export default EventService;
