import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { hotjar } from 'react-hotjar';

import { Container } from 'styles';
import { RenderIf } from 'components/layout';
import { Button, ButtonLink } from 'styles/Form';
import AuthService from 'services/AuthService';
import NavBarMobile from './NavBarMobile';
import PixelContext from 'context/pixel';
import UserContext from 'context/user';
import * as S from './styles';

const NavBar = () => {
	const history = useHistory();
	const location = useLocation();
	const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
	const [showUserOptions, setShowUserOptions] = useState(false);
	const { state: userState, setState: setUserState } = useContext(UserContext);
	const { state: pixelState } = useContext(PixelContext);
	const appName = process.env.REACT_APP_NAME ? process.env.REACT_APP_NAME.split(' ').join('') : 'app';

	useEffect(() => {
		const hotjarId: any = process.env.REACT_APP_HOTJAR_ID;
		const hotjarVersion: any = process.env.REACT_APP_HOTJAR_VERSION;

		AuthService.clearCurrentUser();
		hotjar.initialize(hotjarId, hotjarVersion);
		if (process.env.REACT_APP_GA_CODE) {
			window.gtag('config', process.env.REACT_APP_GA_CODE);
		}

		validateUserData();
		checkLocation();
	}, []);

	useEffect(() => {
		validateUserData(location.pathname);
	}, [location]);

	useOnClickOutside(inputRef, () => {
		setShowUserOptions(false)
	});

	// TODO implementar logout completo
	const logout = () => {
		clearAuthData();
		history.push({ pathname: '/' });
	}

	const validateUserData = (path = '') => {
		if (AuthService.isAuthenticated()) {
			if (AuthService.hasLocalUser()) {
				const user = AuthService.readUserData();
				setCurrentUserOnContext(user, path);
			} else {
				AuthService.getUserData(AuthService.getAccessToken())
					.then(({ data }: any) => {
						AuthService.setUserData(data);
						setCurrentUserOnContext(data, path);
					});
			}
			return;
		}

		clearAuthData();
	}

	const setCurrentUserOnContext = (user: any, path: string) => {
		if (!localStorage.getItem('IS_DATA_COMPLETED')) {
			localStorage.setItem('IS_DATA_COMPLETED', user.isDataCompleted);
		}
		setUserState({
			isLogged: true,
			firstName: user.firstName,
			lastName: user.lastName,
			photoUrl: user.photoUrl,
			username: user.username,
			isDataCompleted: user.isDataCompleted,
		});

		if (path === '/entrar') {
			history.push({ pathname: '/' });
		}
	}

	const clearAuthData = () => {
		AuthService.clearSession();
		setUserState({
			isLogged: false,
			firstName: '',
			lastName: '',
			photoUrl: '',
			username: '',
			isDataCompleted: true,
		});
	}

	const checkLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				() => null,
				() => null /// TODO enviar para o back o motivo
			)
		} else {
			// TODO enviar para o back que não tem suporte
		}
	}

	return (
		<>
			<S.NavBarWrapper>
				<Container>
					<S.NavBarContent>
						{pixelState.active
							? (<S.NavLogoExternal href={process.env.REACT_APP_URL} title={process.env.REACT_APP_NAME} />)
							: (<S.NavLogo to="/" title={process.env.REACT_APP_NAME} /> )
						}

						<S.NavBarLinks>
							<RenderIf condition={!userState.isLogged}>
								<>
									<ButtonLink id="desktopGotoLogin" to={{ pathname: '/entrar', state: {previousUrl: location.pathname} }}>Entrar</ButtonLink>
									<ButtonLink id="desktopGotoRegister" to="/cadastrar" margin="0 1rem">Cadastre-se</ButtonLink>
								</>
							</RenderIf>

							<Button id="desktopGotoCreateEvent" as="a" href={`${process.env.REACT_APP_PRODUCER_URL}/evento/novo`}>Crie seu evento</Button>

							<RenderIf condition={userState.isLogged}>
								<S.UserWrapper>
									<S.UserImage
										id="toggleUserOptions"
										image={userState.photoUrl}
										onClick={() => showUserOptions ? setShowUserOptions(false) : setShowUserOptions(true)}
									/>
									<S.UserOptionList ref={inputRef} isMenuOpen={showUserOptions}>
										{pixelState.active
											? (<S.UserOptionExternal id="desktopMyTickets" href={`${process.env.REACT_APP_URL}/meus-ingressos`}>Meus ingressos</S.UserOptionExternal>)
											: (<S.UserOption id="desktopMyTickets" to="/meus-ingressos" onClick={() => setShowUserOptions(false)}>Meus ingressos</S.UserOption>)
										}
										<S.UserOptionExternal id="desktopMyEvents" href={`${process.env.REACT_APP_PRODUCER_URL}/meus-eventos`}>Meus eventos</S.UserOptionExternal>
										<S.UserOptionExternal id="desktopMyAccount" href={`${process.env.REACT_APP_PRODUCER_URL}/minha-conta`}>Minha conta</S.UserOptionExternal>
										<S.UserOption id="desktopLogout" to="#" onClick={logout}>Sair</S.UserOption>
									</S.UserOptionList>
								</S.UserWrapper>
							</RenderIf>
						</S.NavBarLinks>
					</S.NavBarContent>
				</Container>
			</S.NavBarWrapper>

			<NavBarMobile user={userState} logoutFn={logout} />
		</>
	);
}

export default NavBar;

function useOnClickOutside(ref: any, handler: any) {
	useEffect(
		() => {
			const listener = (event: any) => {
				if (!ref.current || ref.current.contains(event.target)) {
					return;
				}

				handler(event);
			};

			document.addEventListener('mousedown', listener);
			document.addEventListener('touchstart', listener);

			return () => {
				document.removeEventListener('mousedown', listener);
				document.removeEventListener('touchstart', listener);
			};
		},
		[ref, handler]
	);
}
