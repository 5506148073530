export enum MIGRATE_VALIDATE_CODE_TYPE {
	EMAIL = 'EMAIL',
	PHONE = 'PHONE',
}

export enum STATE {
	AC = 'AC',
	AL = 'AL',
	AP = 'AP',
	AM = 'AM',
	BA = 'BA',
	CE = 'CE',
	DF = 'DF',
	ES = 'ES',
	GO = 'GO',
	MA = 'MA',
	MT = 'MT',
	MS = 'MS',
	MG = 'MG',
	PA = 'PA',
	PB = 'PB',
	PR = 'PR',
	PE = 'PE',
	PI = 'PI',
	RJ = 'RJ',
	RN = 'RN',
	RS = 'RS',
	RO = 'RO',
	RR = 'RR',
	SC = 'SC',
	SP = 'SP',
	SE = 'SE',
	TO = 'TO',
}

export enum GENDER {
	MALE = 'MALE',
	FEMALE = 'FEMALE',
	OTHERS = 'OTHERS',
	NO_ANSWER = 'NO_ANSWER',
}
