import { call, put } from 'redux-saga/effects';

import { getCategoriesSuccess, getCategoriesFailure } from './actions';
import {eventApi, ordersApi} from '../../../services/Api';

export function* getCategoriesSaga() {
	try {
		const response = yield call(ordersApi.get, '/v1/public/event/all-categories');
		yield put(getCategoriesSuccess(response.data));
	} catch (err) {
		yield put(getCategoriesFailure());
	}
}
