import styled from 'styled-components';
import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';

type StyleProps = {
	show: boolean;
}

export const CompleteUserDataWarning = styled.div`
	align-items: flex-start;
	justify-content: flex-start;
	background-color: #fff;
	border-top-right-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
	bottom: 4.5rem;
	box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
	display: flex;
	gap: 0.5rem;
	left: ${({ show }: StyleProps) => show ? 0 : '-100%'};
	max-width: 270px;
	padding: 0.5rem;
	position: fixed;
	transition: left 1s ease;
	z-index: 6000;

	${breakAt(BreakpointsSizes.lg)} {
		bottom: 1.5rem;
		max-width: 360px;
	}
`;

export const CompleteUserDataContent = styled.div`
	cursor: pointer;
	display: flex;
	gap: 0.5rem;
	padding: 0.5rem 1rem;
`;

export const CompleteUserDataImage = styled.div`
	border: 1px solid;
`;

export const CompleteUserDataTitle = styled.div`
	font-size: 0.85rem;
	font-weight: 600;
	margin-bottom: 0.25rem;

	${breakAt(BreakpointsSizes.lg)} {
		font-size: 1rem;
	}
`;

export const CompleteUserDataMessage = styled.div`
	font-size: 0.7rem;

	${breakAt(BreakpointsSizes.lg)} {
		font-size: 0.85rem;
	}
`;

export const CompleteUserDataClose = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
	height: 30px;
	justify-content: center;
	min-width: 30px;
`;
