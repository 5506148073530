import axios from 'axios';
import { ordersApi } from './Api';

const AddressService = (() => {
	function getAddressByZipCode(zipCode: string) {
		return axios.get(`https://viacep.com.br/ws/${zipCode}/json`);
	}

	function getCities(params: any) {
		return ordersApi.get(`/v3/public/address/cities`, { params });
	}

	return {
		getAddressByZipCode: getAddressByZipCode,
		getCities: getCities,
	}
})();

export default AddressService;
