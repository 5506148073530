import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { ChevronLeft } from 'react-feather';
import Select from 'react-select';

import { Button, Input, InputErrorMessage, InputWrapper, Label, MaskInput } from 'styles/Form';
import UserService from 'services/UserService';
import { RenderIf } from 'components/layout';
import * as S from '../styles';
import { isValidCpf, transformDate } from 'utils/appUtils';
import PhoneAuthResendCode from '../PhoneAuthResendCode/PhoneAuthResendCode';
import { GENDER, STATE } from 'config/enums';
import AddressService from 'services/AddressService';

enum PHONE_REGISTER_VIEW {
	USER_VIEW = 'USER_VIEW',
	PHONE_VIEW = 'PHONE_VIEW',
	CODE_VIEW = 'CODE_VIEW',
	EMAIL_VIEW = 'EMAIL_VIEW',
}

const PhoneRegister = ({ showBackButton = true, backFn, registerSuccessFn }: any) => {
	const [currentView, setCurrentView] = useState(PHONE_REGISTER_VIEW.USER_VIEW);

	const [isLoading, setIsLoading] = useState(false);

	const [cpf, setCpf] = useState({ value: '', hasError: false });
	const [name, setName] = useState({ value: '', hasError: false });
	const [userForm, setUserForm] = useState({ invalid: true });

	const [phoneNumber, setPhoneNumber] = useState({ value: '', hasError: false });
	const [apiKey, setApiKey] = useState('');

	const [code, setCode] = useState({ value: '', hasError: false });

	const stateSelectRef = useRef(null);
	const citySelectRef = useRef(null);
	const [email, setEmail] = useState({ value: '', hasError: false });
	const [emailConfirmation, setEmailConfirmation] = useState({ value: '', hasError: false });
	const [emailForm, setEmailForm] = useState({ invalid: true });
	const [genderList, setGenderList] = useState<any>([]);
	const [gender, setGender] = useState(null as any);
	const [birthDate, setBirthDate] = useState('');
	const [zipcode, setZipcode] = useState('');
	const [street, setStreet] = useState({ value: '', disabled: false });
	const [number, setNumber] = useState('');
	const [complement, setComplement] = useState('');
	const [neighborhood, setNeighborhood] = useState({ value: '', disabled: false });
	const [state, setState] = useState({ value: '' as any });
	const [city, setCity] = useState({ value: '' as any });
	const [isGettingCities, setIsGettingCities] = useState(false);
	const [stateList, setStateList] = useState<any>([]);
	const [cityList, setCityList] = useState<any>([]);
	const [isStateDisabled, setIsStateDisabled] = useState(false);
	const [isCityDisabled, setIsCityDisabled] = useState(false);

	const selectStyle = {
		control: (baseStyles: any) => ({
			...baseStyles,
			borderColor: '#E0E0E0',
			borderRadius: '0.5rem',
			minHeight: '44px'
		}),
		menuPortal: (provided: any) => ({
			...provided,
			zIndex: 100
		})
	}

	useEffect(() => {
		getGenderList();
		getStateList();
	}, []);

	useEffect(() => {
		const isFormInvalid = (!cpf.value || cpf.hasError || !name.value || name.hasError);
		setUserForm({invalid: isFormInvalid});
	}, [cpf, name]);

	useEffect(() => {
		const isFormInvalid = (!email.value || email.hasError || !emailConfirmation.value || emailConfirmation.hasError);
		setEmailForm({invalid: isFormInvalid});
	}, [email, emailConfirmation]);

	const changeCpf = (ev: any) => {
		ev.persist();

		const cleanValue = ev.target.value.replace(/[^0-9]/g, '').substring(0, 11);
		setCpf(() => ({
			hasError: cleanValue.length > 0 && !isValidCpf(cleanValue),
			value: cleanValue
		}));
	}

	const changeName = (ev: any) => {
		ev.persist();
		const value = ev.target.value;
		const nameArray = value.split(' ').filter((name: string) => name.length > 0);

		setName(() => ({
			hasError: value.length === 0 || nameArray.length < 2,
			value: value
		}));
	}

	const changePhoneNumber = (ev: any) => {
		ev.persist();
		const cleanValue = ev.target.value.replace(/\D/g,'').substring(0, 11);

		setPhoneNumber(() => ({
			hasError: cleanValue.length === 0 || cleanValue.length < 11,
			value: cleanValue
		}));
	}

	const changeCode = (ev: any) => {
		ev.persist();
		setCode(() => ({
			hasError: ev.target.value.length !== 6,
			value: ev.target.value
		}));
	}

	const changeEmail = (ev: any) => {
		ev.persist();
		setEmail(() => {
			setEmailConfirmation((data) => ({
				...data,
				hasError: email.value.length !== 0 && data.value.length === 0 || data.value !==  ev.target.value || !data.value.match(/^[\w\.\-_]{1,}@[\w\.\-]{6,}/),
			}));

			return {
				hasError: ev.target.value.length === 0 || !ev.target.value.match(/^[\w\.\-_]{1,}@[\w\.\-]{6,}/),
				value: ev.target.value
			}
		});
	}

	const changeEmailConfirmation = (ev: any) => {
		ev.persist();
		setEmailConfirmation(() => ({
			hasError: ev.target.value.length === 0 || ev.target.value !== email.value || !ev.target.value.match(/^[\w\.\-_]{1,}@[\w\.\-]{6,}/),
			value: ev.target.value
		}));
	}

	const goToPhoneData = (ev: any) => {
		ev.preventDefault();
		setIsLoading(() => true);

		UserService.checkDocumentIsValid(cpf.value)
			.then(() => setCurrentView(() => PHONE_REGISTER_VIEW.PHONE_VIEW))
			.catch(({ data }) => toast(data.message, { type: toast.TYPE.ERROR }))
			.finally(() => setIsLoading(() => false));
	}

	const goToCodeData = (ev: any) => {
		ev.preventDefault();
		setIsLoading(() => true);
		UserService.checkPhoneAndSendCode(phoneNumber.value)
			.then(({ data }) => {
				setApiKey(data);
				setCurrentView(() => PHONE_REGISTER_VIEW.CODE_VIEW);
			})
			.catch(({ data }) => toast(data.message, { type: toast.TYPE.ERROR }))
			.finally(() => setIsLoading(() => false));
	}

	const resendWhatsapp = () => {
		UserService.resendWhatsappRegisterCode(phoneNumber.value, apiKey)
			.then(({ data }) => {
				setApiKey(data);
				toast('Código de verificação enviado por Whatsapp', { type: toast.TYPE.SUCCESS });
			})
			.catch(({ data }) => toast(data.message, { type: toast.TYPE.ERROR }));
	}

	const resendSms = () => {
		UserService.resendSmsRegisterCode(phoneNumber.value, apiKey)
			.then(({ data }) => toast(data.message, { type: toast.TYPE.SUCCESS }))
			.catch(({ data }) => toast(data.message, { type: toast.TYPE.ERROR }));
	}

	const goToEmailData = (ev: any) => {
		ev.preventDefault();
		setIsLoading(() => true);
		const params = { phone: phoneNumber.value, code: code.value };

		UserService.validatePhoneRegisterCode(params, apiKey)
			.then(() => setCurrentView(() => PHONE_REGISTER_VIEW.EMAIL_VIEW))
			.catch(({ data }) => toast(data.message, { type: toast.TYPE.ERROR }))
			.finally(() => setIsLoading(() => false));
	}

	const concludeRegister = (ev: any) => {
		ev.preventDefault();
		setIsLoading(() => true);

		const payload = {
			phone: phoneNumber.value,
			name: name.value,
			document: cpf.value,
			code: code.value,
			email: email.value,
			confirmEmail: emailConfirmation.value,
			birthDate: birthDate ? transformDate(birthDate) : null,
			gender: gender ? gender.value : null,
			userAddress: {
				zipCode: zipcode ? zipcode.replace(/[^0-9]/g, '') : null,
				street: street.value || null,
				number: number || null,
				complement: complement || null,
				neighborhood: neighborhood.value || null,
				city: city.value || null,
				state: state.value || null,
			}
		}

		UserService.concludePhoneRegister(payload, apiKey)
			.then(({ data }) => {
				toast(data.message, { type: toast.TYPE.SUCCESS });
				registerSuccessFn('PHONE');
			})
			.catch(({ data }) => toast(data.message, { type: toast.TYPE.ERROR }))
			.finally(() => setIsLoading(false))
	}

	const backView = () => {
		if (currentView === PHONE_REGISTER_VIEW.USER_VIEW) {
			backFn();
			return;
		}

		if (currentView === PHONE_REGISTER_VIEW.PHONE_VIEW) {
			setCurrentView(() => PHONE_REGISTER_VIEW.USER_VIEW);
			return;
		}

		if (currentView === PHONE_REGISTER_VIEW.CODE_VIEW) {
			setCurrentView(() => PHONE_REGISTER_VIEW.PHONE_VIEW);
		}

		if (currentView === PHONE_REGISTER_VIEW.EMAIL_VIEW) {
			setCurrentView(() => PHONE_REGISTER_VIEW.CODE_VIEW);
		}
	}

	const getGenderList = () => {
		setGenderList([
			{ label: 'Masculino', value: GENDER.MALE },
			{ label: 'Feminino', value: GENDER.FEMALE },
			{ label: 'Outros', value: GENDER.OTHERS },
			{ label: 'Prefiro não responder', value: GENDER.NO_ANSWER },
		])
	}

	const getStateList = () => {
		setStateList(Object.keys(STATE).map((state: string) => ({ label: state, value: state })))
	}

	const changeZipCode = (ev: any) => {
		ev.persist();
		const value = ev.target.value;

		if (value.length === 9) {
			const zipCode = value.replace(/[^0-9]/g, '');
			getAddressByZipCode(zipCode);
		} else {
			setStreet((old) => ({ ...old, disabled: false }));
			setNeighborhood((old) => ({ ...old, disabled: false }));
			setIsStateDisabled(false);
			setIsCityDisabled(false);
		}

		setZipcode(() => value);
	}

	const getAddressByZipCode = (zipCode: string) => {
		AddressService.getAddressByZipCode(zipCode)
			.then(({ data }) => {
				if (!('erro' in data)) {
					setNeighborhood(() => ({ value: data.bairro, disabled: true }));
					setState({ value: data.uf });
					//@ts-ignore
					stateSelectRef.current.setValue({ label: data.uf, value: data.uf });
					setIsStateDisabled(true);
					setStreet({ value: data.logradouro, disabled: true });

					getUniqueCity(data.uf, data.localidade);
				}
			})
	}

	const getCities = (state: string, cityName: string = '') => {
		setIsGettingCities(true);
		const params = { state, query: cityName }
		AddressService.getCities(params)
			.then(({ data }) => {
				setCityList(() => data.content.map((city: any) => ({ label: city.name, value: city.id })));
			})
			.finally(() => setIsGettingCities(false))
	}

	const getUniqueCity = (state: string, cityName: string) => {
		const params = { state, query: cityName }
		AddressService.getCities(params)
			.then(({ data }) => {
				const option = { label: data.content[0].name, value: data.content[0].id };
				setCityList([option]);
				//@ts-ignore
				citySelectRef.current.setValue(option);
				setIsCityDisabled(true);
			});
	}

	const searchCities = (inputValue: string) => {
		if (inputValue.length >= 3) {
			getCities(state.value, inputValue);
		}
	}

	const changeState = (ev: any) => {
		getCities(ev.value);

		setState(() => ({
			value: ev.value,
			disabled: false
		}));
	}

	return (
		<>
			<S.Header>
				<RenderIf condition={showBackButton || currentView !== PHONE_REGISTER_VIEW.USER_VIEW}>
					<S.ReturnButton onClick={backView}>
						<ChevronLeft size="1rem" />
					</S.ReturnButton>
				</RenderIf>
				<S.Title>Crie sua conta</S.Title>
			</S.Header>

			<RenderIf condition={currentView === PHONE_REGISTER_VIEW.USER_VIEW}>
				<form onSubmit={goToPhoneData}>
					<S.SmallText>Informe seus dados para criar sua conta</S.SmallText>

					<InputWrapper hasError={cpf.hasError}>
						<Label>CPF</Label>
						<MaskInput id="cpf" mask="999.999.999-99" value={cpf.value} maskPlaceholder={null} placeholder="000.000.000-00" onChange={changeCpf} />
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>

					<InputWrapper hasError={name.hasError}>
						<Label data-required>Nome e sobrenome</Label>
						<Input id="name" placeholder="Insira seu nome e sobrenome" value={name.value} onChange={changeName} />
						<InputErrorMessage>Insira seu nome e sobrenome</InputErrorMessage>
					</InputWrapper>

					<Button
						id="validatePhoneRegisterData"
						width="100%"
						margin="2.25rem 0 0"
						loading={isLoading}
						disabled={userForm.invalid}>Continuar</Button>
				</form>
			</RenderIf>

			<RenderIf condition={currentView === PHONE_REGISTER_VIEW.PHONE_VIEW}>
				<S.SmallText>Informe seus dados para criar sua conta</S.SmallText>
				<form onSubmit={goToCodeData}>
					<InputWrapper hasError={phoneNumber.hasError}>
						<Label data-required>Telefone</Label>
						<MaskInput
							id="phoneNumber"
							mask="(99) 99999-9999"
							value={phoneNumber.value}
							maskPlaceholder={null}
							placeholder="(00) 00000-0000"
							onChange={changePhoneNumber} />
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>

					<Button
						id="validatePhoneRegisterPhone"
						width="100%"
						margin="2.25rem 0 0"
						disabled={phoneNumber.value.length === 0 || phoneNumber.hasError}
						loading={isLoading}>Continuar</Button>
				</form>
			</RenderIf>

			<RenderIf condition={currentView === PHONE_REGISTER_VIEW.CODE_VIEW}>
				<S.SmallText>Digite o código que você recebeu por SMS ou Whatsapp.</S.SmallText>
				<form onSubmit={goToEmailData}>
					<InputWrapper hasError={code.hasError}>
						<Label data-required>Código</Label>
						<MaskInput
							id="code"
							mask="999999"
							value={code.value}
							maskPlaceholder={null}
							placeholder="000000"
							onChange={changeCode} />
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>

					<Button
						id="validatePhoneRegisterCode"
						width="100%"
						margin="2.25rem 0 0"
						disabled={code.value.length === 0 || code.hasError}
						loading={isLoading}>Verificar código</Button>
				</form>
				<PhoneAuthResendCode hasOptions resendWhatsappFn={resendWhatsapp} resendSmsFn={resendSms} />
			</RenderIf>

			<RenderIf condition={currentView === PHONE_REGISTER_VIEW.EMAIL_VIEW}>
				<S.SmallText>Informe seus dados para criar sua conta.</S.SmallText>
				<form onSubmit={concludeRegister}>
					<InputWrapper hasError={email.hasError}>
						<Label data-required>E-mail</Label>
						<Input id="email" placeholder="seunome@email.com" value={email.value} onChange={changeEmail} />
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>

					<InputWrapper hasError={emailConfirmation.hasError}>
						<Label data-required>Confirmar e-mail</Label>
						<Input id="emailConfirmation" placeholder="seunome@email.com" value={emailConfirmation.value} onChange={changeEmailConfirmation} />
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>

					<InputWrapper>
						<Label>Data de nascimento</Label>
						<MaskInput id="birthDate" mask="99/99/9999" value={birthDate} maskPlaceholder={null} onChange={($event) => setBirthDate($event.target.value)} />
					</InputWrapper>

					<InputWrapper>
						<Label>Gênero</Label>
						<Select
							defaultValue={gender}
							onChange={(option) => setGender(option)}
							options={genderList}
							placeholder=""
							styles={selectStyle}
						/>
					</InputWrapper>

					<InputWrapper>
						<Label>CEP</Label>
						<MaskInput id="zipcode" mask="99999-999" value={zipcode} maskPlaceholder={null} onChange={changeZipCode} />
					</InputWrapper>

					<InputWrapper>
						<Label>Rua</Label>
						<Input id="street" value={street.value} disabled={street.disabled} onChange={($event) => setStreet({ value: $event.target.value, disabled: false })} />
					</InputWrapper>

					<InputWrapper>
						<Label>Número</Label>
						<MaskInput id="number" mask="99999" value={number} maskPlaceholder={null} onChange={($event) => setNumber($event.target.value)} />
					</InputWrapper>

					<InputWrapper>
						<Label>Complemento</Label>
						<Input id="complement" value={complement} onChange={($event) => setComplement($event.target.value)} />
					</InputWrapper>

					<InputWrapper>
						<Label>Bairro</Label>
						<Input id="neighborhood" value={neighborhood.value} disabled={neighborhood.disabled} onChange={($event) => setNeighborhood({ value: $event.target.value, disabled: false })} />
					</InputWrapper>

					<InputWrapper>
						<Label>Estado</Label>
						<Select
							ref={stateSelectRef}
							defaultValue={state.value}
							onChange={changeState}
							options={stateList}
							isDisabled={isStateDisabled}
							noOptionsMessage={() => 'Nenhum estado encontrado'}
							maxMenuHeight={100}
							placeholder=""
							styles={selectStyle}
						/>
					</InputWrapper>

					<InputWrapper>
						<Label>Cidade</Label>
						<Select
							ref={citySelectRef}
							defaultValue={city.value}
							onChange={(option) => setCity({ value: option.value })}
							onInputChange={searchCities}
							options={cityList}
							placeholder=""
							noOptionsMessage={() => 'Nenhuma cidade encontrada'}
							isDisabled={!state.value || isCityDisabled}
							isLoading={isGettingCities}
							loadingMessage={() => 'Buscando cidades'}
							maxMenuHeight={100}
							styles={selectStyle}
						/>
					</InputWrapper>

					<S.TermsText>Ao me cadastrar, concordo com os <a href={`${process.env.REACT_APP_FAQ_URL}/termos-de-uso`} target="_blank">Termos de Uso</a> da plataforma</S.TermsText>

					<Button
						id="concludePhoneRegister"
						width="100%"
						margin="2.25rem 0 0"
						disabled={emailForm.invalid}
						loading={isLoading}>Criar conta</Button>
				</form>
			</RenderIf>
		</>
	);
}

export default PhoneRegister;
