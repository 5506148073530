import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
import { toast } from 'react-toastify';

import { CompleteUserForm, Loading, ModalAuth } from 'components/standalone';
import { CheckoutState } from 'store/ducks/checkout/types';
import { loadSuccess } from 'store/ducks/checkout/actions';
import { hideMobileOptions, showMobileOptions } from 'store/ducks/nav/actions';
import { Seo } from 'components/standalone';
import { loadCheckout } from 'services/ScriptLoader';
import { RenderIf } from 'components/layout';
import PixelManagement from 'components/layout/pixelManagement/PixelManagement';
import PixelContext from 'context/pixel';
import UserContext from 'context/user';
import AuthService from 'services/AuthService';

interface StateProps {
	checkout: CheckoutState,
}

const CheckoutPage = () => {
	const { reserve } = useParams();
	const { state }: any = useLocation();
	const { state: pixelState } = useContext(PixelContext);
	const { state: userState, setState: setUserState } = useContext(UserContext);
	const [ pixelValid, setPixelValid ] = useState(false);
	const [ isModalOpen, setIsModalOpen ] = useState(false);
	const [ hasEventInsights, setHasEventInsights ] = useState(false);
	const [ geoLocation, setGeoLocation ] = useState({ latitude: '', longitude: '' });
	const select = (state: StateProps) => state.checkout;
	const { loading } = useSelector(select);
	const history = useHistory();
	const dispatch = useDispatch();
	const hideLoading = () => loadSuccess();
	let component: any;

	const [showForm, setShowForm] = useState(false);

	const handle = (comp: any) => {
		component = comp;
	}

	const gotoSuccess = (ev: any) => {
		history.push({
			pathname: '/inscricao-sucesso',
			state: { data: ev.detail, hasMarketing: state?.hasMarketing || false },
		})
	}

	useEffect(() => {
		dispatch(hideMobileOptions());
		getLocation();
	}, []);

	useEffect(() => {
		if (!userState.isLogged) {
			setIsModalOpen(() => true);
		} else {
			validateInsightsFlag(hasEventInsights);
		}
	}, [userState]);

	const returnToEvent = (ev: any) => {
		history.goBack();
	}

	useEffect(() => {
		loadCheckout(() => dispatch(hideLoading()));

		component.addEventListener('redirect', returnToEvent);
		component.addEventListener('success', gotoSuccess);
		component.addEventListener('sendInsightsFlag', saveEventInsightParams);
	}, []);

	useEffect(() => {
		return () => {
			dispatch(showMobileOptions())
		}
	}, []);

	const triggerInitiateCheckoutEvent = (ev: any) => {
		if (pixelState.active && pixelState.eventInitiateCheckout) {
			const data = {
				currency: process.env.REACT_APP_CURRENCY,
				value: ev.detail.value,
				num_items: ev.detail.quantity,
				contents: ev.detail.items,
			};
			ReactPixel.track('InitiateCheckout', data);
		}
	}

	const triggerPurchaseEvent = (ev: any) => {
		if (pixelState.active && pixelState.eventCheckout) {
			const data = {
				currency: process.env.REACT_APP_CURRENCY,
				value: ev.detail.value,
				num_items: ev.detail.quantity,
				contents: ev.detail.items,
			}
			ReactPixel.track('Purchase', data);
		}
	}

	useEffect(() => {
		if (pixelState.active && !pixelValid) {
			setPixelValid(true);
			component.addEventListener('initCheckout', triggerInitiateCheckoutEvent);
			component.addEventListener('concludeCheckout', triggerPurchaseEvent);
		}
	}, [pixelState]);

	const getLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((pos) => {
				setGeoLocation({
					latitude: pos.coords.latitude.toString(),
					longitude: pos.coords.longitude.toString(),
				})
			}, (e) => {
				// TODO enviar para o back o motivo
			});
			return;
		}
		// TODO enviar para o back que não tem suporte
	}

	const saveEventInsightParams = (ev: any) => {
		setHasEventInsights(() => ev.detail);

		if (ev.detail && userState.isLogged) {
			validateInsightsFlag(ev.detail);
		}
	}

	const validateInsightsFlag = (eventInsightsEnabled: boolean) => {
		const isDataCompleted = JSON.parse(localStorage.getItem('IS_DATA_COMPLETED') as string);
		if (eventInsightsEnabled && !isDataCompleted) {
			setShowForm(true);
		}
	}

	const updateUserData = () => {
		// AuthService.getUserData(AuthService.getAccessToken())
		// 	.then(({ data }: any) => {
		// 		AuthService.setUserData(data);
		// 		setUserState({
		// 			isLogged: true,
		// 			firstName: data.firstName,
		// 			lastName: data.lastName,
		// 			photoUrl: data.photoUrl,
		// 			username: data.username,
		// 			isDataCompleted: true, // TODO temporário precisa atualizar pelo me
		// 		});
		// 	})
		// 	.catch((err: any) => console.log(err));

		toast('Dados completados com sucesso.', { type: toast.TYPE.SUCCESS })
		setUserState({
			...userState,
			isDataCompleted: true
		});
		localStorage.setItem('IS_DATA_COMPLETED', 'true');
		setShowForm(false);
	}

	return (
		<>
			<Seo title={`${process.env.REACT_APP_NAME} - Finalização de compra`} />
			<RenderIf condition={state?.hasMarketing}>
				<PixelManagement eventId={state?.eventId} />
			</RenderIf>

			<RenderIf condition={loading}>
				<Loading text="Aguarde um momento, estamos gerando o seu pedido." />
			</RenderIf>

			<RenderIf condition={reserve}>
				<io-checkout
					reserve={reserve}
					tenant={process.env.REACT_APP_TENANT_ID}
					user={JSON.stringify(userState)}
					color={process.env.REACT_APP_COLOR_MAIN}
					geolocation={JSON.stringify(geoLocation)}
					ref={handle}
				/>
			</RenderIf>

			<ModalAuth
				needAuth={!userState.isLogged}
				isModalOpen={isModalOpen}
				closeModalFn={() => setIsModalOpen(() => false)}
			/>

			<CompleteUserForm isOpen={showForm} showClose={false} successFn={updateUserData} />
		</>
	);
}

export default CheckoutPage;
